import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import galleries from "../../data/galleries/galleries-data";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import MainTitle from "../../components/mainTitle/MainTitle";

const GalleryPage = () => {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { id } = useParams();
  const gallery = galleries.find((item) => item.id === parseInt(id, 10));
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
      <div className='container'>
        <MainTitle title={gallery.title}/>
        <Gallery photos={gallery.photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                    currentIndex={currentImage}
                    views={gallery.photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                />
              </Modal>
          ) : null}
        </ModalGateway>
      </div>
  )
}

export default GalleryPage;