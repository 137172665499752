const article_2 = {
  id: 2,
  path: 'articles',
  title: 'Рождество Христово ',
  date: '07.01.2021',
  previewText: 'В конце поздней Литургии настоятель храма протоиерей Иоанн Грицько поздравил присутствующих с праздником.',
  previewImgUrl: '/img/articles/main_page/rojd_hr_2.jpg',
  content: `
  <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/rojd_hr_2.jpg" class="img-fluid" alt="...">
  </div>
    <p>7 января, в светлый праздник Рождества Христова, в храме преп. Серафима Саровского в Пуще-Водице Оболонского благочиния Северного викариатства г. Киева было совершенно две Божественный Литургии. Ранняя совершалась ночью, а поздняя утром. В конце поздней Литургии настоятель храма протоиерей Иоанн Грицько поздравил присутствующих с праздником.</p>
   
  `
}

export default article_2;