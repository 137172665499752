import React from "react";
import {Link} from "react-router-dom";

const ArticlePreviewItem = ({item: {id, title, date, previewText, previewImgUrl, path}}) => (
  <div className="no-gutters rounded overflow-hidden mb-4 shadow-sm h-md-250 position-relative">
    <img src={previewImgUrl} alt='' className="img-fluid" />
    <div className="p-4">
      {/*<strong className="d-inline-block mb-2 text-primary">World</strong>*/}
      <h5 className="mb-0">{title}</h5>
      <div className="mb-1 text-muted">{date}</div>
      <p className="card-text mb-auto">{previewText}</p>
      <Link to={`/${path}/${id}`} className="stretched-link">Продолжить чтение</Link>
    </div>
  </div>
)

export default ArticlePreviewItem;