import React from "react";
import Article from "../../components/article/Article";
import schoolData from "../../data/school-data";

const SchoolPage = () => (
    <div className='container'>
      <Article article={schoolData} />
    </div>
)

export default SchoolPage;