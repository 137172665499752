const gallery_9 = {
    id: 9,
    path: 'gallery',
    title: 'Престольный праздник 2021',
    date: '01.08.2021',
    previewText: '',
    previewImgUrl: '/img/gallery/prest_pr/15.jpg',
    photos: [
      {
        src: "/img/gallery/prest_pr/01.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/02.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/03.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/04.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/05.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/06.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/07.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/08.jpg",
        width: 3,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/09.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/10.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/11.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/12.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/13.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/14.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/15.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/16.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/17.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/18.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/19.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/20.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/21.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/22.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/23.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/24.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/25.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/26.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/27.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/28.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/29.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/30.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/31.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/32.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/33.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/34.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/35.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/36.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/37.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/38.jpg",
        width: 4,
        height: 5
      },
      {
        src: "/img/gallery/prest_pr/39.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/prest_pr/40.jpg",
        width: 5,
        height: 3
      }
      
    ]
  }
  
  export default gallery_9;