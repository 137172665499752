const article_5 = {
    id: 5,
    path: 'articles',
    title: 'Панихида по приснопоминаемом протоиерее Василие Заеве',
    date: '03.01.2021',
    previewText: 'После Божественной Литургии была отслужена панихида.',
    previewImgUrl: '/img/articles/main_page/panihida_1.jpg',
    content: `
    <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/panihida_1.jpg" class="img-fluid" alt="...">
  </div>
      <p>
      3 января настоятель храма прот.Иоанн Грицько вместе с общиной храма преп. Серафима Саровского в Пуще-Водице Оболонского благочиния Северного викариатства г. Киева молитвенно почтила 12-ую годовщину смерти приснопоминаемого профессора Киевской Духовной Академии и настоятеля храма преподобного Серафима Саровского протоиерея Василия Заева.</p>
      <p> В этот день братия и прихожане храма молитвенно почтили память отца Василия, помолившись у его могилы.</p>
      
    <div class="row mb-3">
      <div class="col-md col-sm mb-3 mb-lg-0">
        <div class="d-flex justify-content-center mb-2">  
          <img src="/img/articles/main_page/panihida/img_6.jpg" alt="...">
        </div>
      </div>
      <div class="col-md col-sm mb-3 mb-lg-0">
        <div  class="d-flex justify-content-center mb-2">
          <div ><img src="/img/articles/main_page/panihida/img_7.jpg" alt="..."></div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md col-sm mb-3 mb-lg-0">
        <div class="d-flex justify-content-center mb-2">  
          <img src="/img/articles/main_page/panihida/img_8.jpg" alt="...">
        </div>
      </div>
      <div class="col-md col-sm mb-3 mb-lg-0">
        <div  class="d-flex justify-content-center mb-2">
          <div ><img src="/img/articles/main_page/panihida/img_9.jpg" alt="..."></div>
        </div>
      </div>
    </div>
    `
  }
  
  export default article_5;