import React from 'react';
import Carousel from "../../components/carousel/carousel";
import ArticlePreview from "../../components/article/ArticlePreview";
import articles from "../../data/articles/articles-data";
import Article from "../../components/article/Article";

const HomePage = () => (
    <React.Fragment>
      <Carousel />
      <ArticlePreview />
      <main role="main" className="container">
        <div className="row">
          <div className="col-md-8 blog-main">
            <Article article={articles[0]}/>
          </div>

          <aside className="col-md-4 blog-sidebar">
            <div className="p-4 mb-3 bg-light rounded">
              <h4 className="">Братья и сестры!!!</h4>
              <p>Создана группа Viber где всегда можно задать вопрос, узнать актуальное расписание,  подать записки на службу и многое другое. Желающие присоединяйтесь переходя по ссылке:</p>
              <a href='https://invite.viber.com/?g2=AQBD%2F6%2FeqvbjyUtSbNceBQIj4wvXZYzbJNIaoFclxVbb8E28nfgP%2FZ0xvUqExfVh'>Храм прп.Серафима Саровского в Viber</a>
            </div>

            <div className="card mb-4 shadow-sm">
              <img alt='' src='/img/home/blj_1_1.jpg'/>
              <div className="card-body">
                <p className="card-text">Блаженнеший Митрополит Киевский и всея Украины Онуфрий.</p>
              </div>
            </div>
          </aside>
        </div>
      </main>
    </React.Fragment>
)

export default HomePage;
