const gallery_10 = {
    id: 1,
    path: 'gallery',
    title: 'Паломническая поездка в Чернигов 2021',
    date: '2021',
    previewText: '',
    previewImgUrl: '/img/gallery/Chernigov/img_3.jpg',
    photos: [
      {
        src: "/img/gallery/Chernigov/img_3.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/Chernigov/img_4.jpg",
        width: 4,
        height: 3
      },
      
    ]
  }
  
  export default gallery_10;