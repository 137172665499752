import React from "react";

import galleries from "../../data/galleries/galleries-data";
import ArticlePreviewItem from "../../components/article/ArticlePreviewItem";
import MainTitle from "../../components/mainTitle/MainTitle";

const GalleryListPage = () => (
    <div className='container'>
      <MainTitle title={'Галерея'}/>
      <div className="row">
        {galleries.reverse().map((article) => (
          <div className='col-md-6' key={article.id}>
            <ArticlePreviewItem item={article}/>
          </div>
        ))}
      </div>
    </div>
)

export default GalleryListPage;
