import React from "react";
import Article from "../../components/article/Article";

import contactData from "../../data/contact-data";

import './contact-page.scss';

const ContactPage = () => (
    <div className='container'>
      <Article article={contactData}/>
      <div className='py-5'>
        <iframe className='contact-page__map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3471.3134834384673!2d30.349579605985706!3d50.54064634258619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472b2d6331b9929b%3A0xfaa04adefd4046d1!2sChurch%20of%20St.%20Seraphim%20of%20Sarov!5e0!3m2!1sen!2sua!4v1589802835043!5m2!1sen!2sua"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                title="contactMap"
                tabIndex="0"></iframe>
      </div>
    </div>
)

export default ContactPage;