import React from 'react';
import Logo from "../logo/logo";

import './header.scss';

const Header = () => (
    <header className='blog-header py-3 header'>
      <div className="container">
        <div className='row'>
          <strong className='col-lg-5
                            col-md-12
                            d-flex
                            align-items-lg-left
                            align-items-center
                            justify-content-lg-start
                            justify-content-center
                            order-lg-1
                            order-3
                            text-center'>Храм Преподобного Серафима Саровского</strong>
          <div className='col-lg-2 col-md-12 pb-2 pb-lg-0 order-lg-2'>
            <Logo />
          </div>
          <strong className='col-lg-5
                            col-md-12
                            d-flex
                            justify-content-lg-end
                            justify-content-center
                            align-items-lg-center
                            order-2
                            text-center'>Украинская Православная Церковь</strong>
        </div>
      </div>
    </header>
)

export default Header;