const gallery_6 = {
    id: 6,
    path: 'gallery',
    title: 'День Святой Троицы 2009',
    date: '2009',
    previewText: '',
    previewImgUrl: '/img/gallery/troica_2009/tr_07.jpg',
    photos: [
      {
        src: "/img/gallery/troica_2009/tr_01.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_02.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_03.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_04.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_05.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_06.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/troica_2009/tr_07.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/troica_2009/tr_09.jpg",
        width: 4,
        height: 3
      }
    ]
  }
  
  export default gallery_6;