import React from "react";
import ReactHtmlParser from "react-html-parser";
import MainTitle from "../mainTitle/MainTitle";

import "./article.scss";

const Article = ({article: {title, content}}) => {


  return (
      <React.Fragment>
        <MainTitle title={title}/>
        {ReactHtmlParser(`${content}`)}
      </React.Fragment>
  )
}

export default Article;