import article_1 from './article_1';
import article_2 from './article_2';
import article_3 from './article_3';
import article_4 from './article_4';
import article_5 from './article_5';
import article_6 from './article_6';
import article_7 from './article_7';
import article_8 from './article_8';

const articles = [
  article_1,
  article_2,
  article_3,
  article_4,
  article_5,
  article_6,
  article_7,
  article_8,
]

export default articles;