const article_3 = {
  id: 3,
  path: 'articles',
  title: 'Блаженнейший Митрополит Онуфрий благословил особое молитвенное правило на Великий пост',
  date: '02.05.2020',
  previewText: 'Его Блаженство отметил, что путь Великого поста мы должны проходить с молитвой.',
  previewImgUrl: '/img/articles/main_page/blaj_on_2.jpg',
  content: `
  <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/blaj_on_2.jpg" class="img-fluid" alt="...">
  </div>
        <p>Перед началом Святого Великого поста Блаженнейший Митрополит Киевский и всея Украины Онуфрий обратился к верующим Украинской Православной Церкви со словами наставления и благословил на особое молитвенное правило для мира в Украине, сообщает Информационно-просветительский отдел УПЦ.</p>
        <p>«Всех вас, дорогие братья и сестры, верные чада нашей Святой Украинской Православной Церкви, сердечно поздравляю с наступающим Великим Святым постом! — сказал Предстоятель. — Великий пост — это то время, в течение которого мы должны себя подготовить к встрече с воскресшим Христом. Пост — это то время, когда мы должны идти вместе со Христом на Голгофу».</p>
        <p>Eго Блаженство отметил, что путь Великого поста мы должны проходить с молитвой.</p>
        <p>«С помощью молитвы и поста человек себя духовно совершенствует и становится способным увидеть и понять те великие страдания и подвиг, который ради нашего спасения совершил наш Спаситель Иисус Христос. Кто взойдет с крестом на Голгофу, то сможет вкусить и радости Светлого Христова Воскресения», — пояснил Архипастырь.</p>
        <p>Блаженнейший владыка благословил особенно молитвенное правило: «Я благословляю в течение этого Великого поста каждому христианину, нашим верным читать одну кафизму из Псалтыри и просить Бога, чтобы послал мир на нашу землю и смягчил наши сердца».</p>
        <p>«Пусть Бог благословит всех нас и поможет каждому из нас, по мере наших сил, совершить достойно Святой пост и увидеть и вкусить славу Воскресения Христова!», — пожелал Митрополит Онуфрий.</p>
    
  `
}

export default article_3;