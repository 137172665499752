const gallery_3 = {
  id: 3,
  path: 'gallery',
  title: 'Престольный праздник 2013',
  date: '2013',
  previewText: '',
  previewImgUrl: '/img/gallery/PP_2013/10.jpg',
  photos: [
    {
      src: "/img/gallery/PP_2013/01.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/02.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/03.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/04.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/05.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/06.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/PP_2013/07.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/PP_2013/08.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/PP_2013/09.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/10.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/11.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/12.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/13.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/14.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/15.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/16.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/17.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/18.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/19.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/PP_2013/20.jpg",
      width: 4,
      height: 3
    },
  ]
}

export default gallery_3;