import React from "react";
import {useParams} from "react-router-dom";

import articles from "../../data/articles/articles-data";
import Article from "../../components/article/Article";

const ArticlePage = () => {
  const { id } = useParams();
  const article = articles.find((item) => item.id === parseInt(id, 10));

  return (
      <div className='container'>
        <Article article={article}/>
      </div>
  )
}
export default ArticlePage;