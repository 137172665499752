const contactData = {
  title: 'Контакты',
  content: `
    <p><strong>Адрес:</strong></p>
    <p>04075 ул. Николая Юнкерова, 42.</p>
    <p>Тел. +38-068-107-84-30</p>
    <p>Настоятель протоиерей Иоанн Грицько</p>
    <p><strong>Проезд:</strong></p>
    <p>от ст. м "Нивки" – авт. 226-2, 718, 719;</p>
    <p>от ст. м "Академгородок" – авт. 30;</p>
    
    <p>от пл. Шевченко – трам. 7, 12</p>
    <p>от ст. м "Контрактовая площадь" – трам. 12 до остановки"7-я линия".</p>
    <p>от пгт. Вишневое через ст. м "Академгородок" - авт. 366</p>
    
  `
}

export default contactData;