import React from 'react';

const Carousel = () => (
    <div id="carouselExampleControls" className="carousel mb-5 container" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="/img/carousel/DJI_0529-3.jpg" className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src="/img/carousel/DJI_0533.jpg" className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src="/img/carousel/ext_2_1.4.1.jpg" className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src="/img/carousel/ext_3_1.3.1.jpg" className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src="/img/carousel/ser_sar.jpg" className="d-block w-100" alt="..." />
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
)

export default Carousel;