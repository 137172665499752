const article_4 = {
    id: 4,
    path: 'articles',
    title: 'Престольный праздник храма',
    date: '02.05.2020',
    previewText: 'Во время Божественной Литургии, сугубо помолились о мире в Украине.',
    previewImgUrl: '/img/articles/main_page/ser_sar_2.jpg',
    content: `
    <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/ser_sar_2.jpg" class="img-fluid" alt="...">
  </div>
      <p>15 января, в праздник преставления и второго обретения мощей преподобного Серафима Саровского, храм преподобного Серафима Саровского Северного викариатства Оболонского благочиния г. Киева в Пуще-Водице отметил престольный праздник.</p>
      <p>В этот день праздничное Богослужение совершили Викарии Киевской Митрополиии архиепископ Боярский Феодосий,  епископ Васильковский Николай, и епископ Баришевский Виктор. Им сослужили: благочинный Оболонского благочиния протоиерей Владимир Терещук, благочинный Печерского благочиния протоиерей Владимир Косточка, настоятель храма прот. Иоанн Грицько, духовенство храма и Оболонского благочиния, а также гости в священническом сане.</p>
        <p>Традиционно, во время Божественной Литургии, сугубо помолились о мире в Украине.</p>
      <p>После Божественной Литургии был совершен Крестный ход.</p>
      <p>По окончании богослужения архиеписокоп Феодосий поздравил присутствующих с праздником в честь преподобного Серафима.</p>
      
    
    `
  }
  
  export default article_4;