const article_8 = {
    id: 8,
    path: 'articles',
    title: 'Паломническая поездка в Чернигов',
    date: '02.10.2021',
    previewText: '2 октября 2021 года, по благословению настоятеля храма была организована паломническая поездка в Чернигов',
    previewImgUrl: '/img/articles/main_page/palomnik_2021/Chernigov/img_3.jpg',
    content: `
    <div class="d-flex justify-content-center mb-5"> 
      <img src="/img/articles/main_page/palomnik_2021/Chernigov/img_3.jpg" class="img-fluid" alt="...">
    </div>
      
      <p>
      В субботу 2 октября, в день памяти благоверного великого князя Игоря Черниговского, воскресная школа храма прп. Серафима Саровского посетила город Чернигов.</p>
  
  
      <p> Юные паломники помолились на Божественной Литургии в Спасо-Преображенском соборе, посетили Борисоглебский собор, отправились в Елецкий женский монастырь где приклонились к мощам прп. Лаврентия Черниговского, узнали об истории данного монастыря. </p>
       
      <p> После этого дети и их родители посетили Троицкий собор, поднялись на колокольню с видом на Чернигов, спустились с экскурсоводом в Антониевы пещеры, прогулялись по Болдиным горам, на которых располагаются древние курганы. В конце экскурсии каждый имел возможность прогуляться по центру города.</p>

  
      
    `
  }
  
  export default article_8;