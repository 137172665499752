import React from 'react';

import scheduleData from "../../data/schedule-data";
import MainTitle from "../../components/mainTitle/MainTitle";

const HomePage = () => (
    <div className='container'>
      <MainTitle title={'Расписание Богослужений'}/>
      <div className='row'>
        {scheduleData.map(({id, day, date, events, holiday}) => (
          <div className='col-md-6 pb-5' key={id}>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className={holiday ? 'text-danger' : 'text-muted'}>{day}</span>
              <span className="badge badge-light badge-pill">{date}</span>
            </h4>
            <ul className="list-group mb-3">
              {events.map(({id, title, description, time}) => (
                <li className="list-group-item d-flex justify-content-between" key={id}>
                  <div>
                    <h6 className="my-0">{title}</h6>
                    {description ? <small className="text-muted">{description}</small> : null}
                  </div>
                  <span>{time}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
)

export default HomePage;
