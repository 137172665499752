import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from "./components/header/header";
import HomePage from "./pages/home-page/HomePage";
import SchedulePage from "./pages/schedule-page/SchedulePage";
import Navigation from "./components/navigation/navigation";
import ArticlePage from "./pages/article-page/ArticlePage";
import NewsPage from "./pages/news-page/NewsPage";
import HistoryPage from "./pages/history-page/HistoryPage";
import SchoolPage from "./pages/school/SchoolPage";
import ContactPage from "./pages/contact-page/ContactPage";
import GalleryPage from "./pages/gallery-page/GalleryPage";
import GalleryListPage from "./pages/gallery-page/GalleryListPage";

function App() {
  return (
    <div>
      <Header />
      <Navigation />
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/schedule' component={SchedulePage} />
        <Route exact path='/news' component={NewsPage} />
        <Route path='/articles/:id' component={ArticlePage} />
        <Route path='/history' component={HistoryPage} />
        <Route path='/school' component={SchoolPage} />
        <Route exact path='/gallery' component={GalleryListPage} />
        <Route path='/gallery/:id' component={GalleryPage} />
        <Route path='/contact' component={ContactPage} />
      </Switch>
    </div>
  );
}

export default App;
