import React from "react";

import articles from "../../data/articles/articles-data";
import ArticlePreviewItem from "../../components/article/ArticlePreviewItem";
import MainTitle from "../../components/mainTitle/MainTitle";

const NewsPage = () => (
    <div className='container'>
      <MainTitle title={'Новости'}/>
      <div className="row">
        {articles.reverse().map((article) => (
            <div className='col-md-6'>
              <ArticlePreviewItem item={article} key={article.id} />
            </div>
        ))}
      </div>
    </div>
)

export default NewsPage;
