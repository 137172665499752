const scheduleData = [
  {
    id: 1,
    day: 'Суббота',
    date: '10.09.22',
    holiday: true,
    events: [
      {
        id: 1,
        title: 'Всенощное бдение',
        time: '17:00',
        description: '',
      },
     
    ]
  },
  {
    id: 2,
    day: 'Воскресенье',
    date: '11.09.22',
    holiday: true,
    events: [
      {
        id: 1,
        title: 'Усекновение главы Пророка, Предтечи и Крестителя Господня Иоанна',
        time: '',
        description: '',
      },
      {
        id: 2,
        title: 'Часы.Божественная Литургия.',
        time: '08:30',
        description: '',
      },
      
    ]
  }
  

]

export default scheduleData;