const gallery_2 = {
  id: 2,
  path: 'gallery',
  title: 'Пасха 2010',
  date: '2010',
  previewText: '',
  previewImgUrl: '/img/gallery/pasha_2010/01.jpg',
  photos: [
    {
      src: "/img/gallery/pasha_2010/01.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/02.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/03.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/04.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/05.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/pasha_2010/06.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/07.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/pasha_2010/08.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/09.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/pasha_2010/10.jpg",
      width: 4,
      height: 3
    }
  ]
}

export default gallery_2;