const gallery_1 = {
  id: 1,
  path: 'gallery',
  title: 'Храм',
  date: '02.05.2020',
  previewText: '',
  previewImgUrl: '/img/gallery/img_1_1.jpg',
  photos: [
    {
      src: "/img/gallery/img_1_1.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_2.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_3.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_4.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_5.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_6.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_7.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_8.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_9.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_10.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_11.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_12.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_13.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_14.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_15.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/img_16_1.jpg",
      width: 1,
      height: 1
    }
  ]
}

export default gallery_1;