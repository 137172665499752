import React from "react";
import Article from "../../components/article/Article";
import historyData from "../../data/history-data";

const HistoryPage = () => (
    <div className='container'>
      <Article article={historyData} />
    </div>
)

export default HistoryPage;