import gallery_1 from './gallery_1';
import gallery_2 from './gallery_2';
import gallery_3 from './gallery_3';
import gallery_4 from './gallery_4';
import gallery_5 from './gallery_5';
import gallery_6 from './gallery_6';
import gallery_7 from './gallery_7';
import gallery_8 from './gallery_8';
import gallery_9 from './gallery_9';
import gallery_10 from './gallery_10';


const galleries = [
  gallery_1,
  gallery_2,
  gallery_3,
  gallery_4,
  gallery_5,
  gallery_6,
  gallery_7,
  gallery_8,
  gallery_9,
  gallery_10
]

export default galleries;