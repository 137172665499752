const article_7 = {
    id: 7,
    path: 'articles',
    title: 'Престольный праздник храма 2021',
    date: '01.08.2021',
    previewText: 'Возглавил Божественную Литургию Предстоятель Украинской Православной Церкви',
    previewImgUrl: '/img/articles/main_page/prest_pr/15.jpg',
    content: `
    <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/prest_pr/15.jpg" class="img-fluid" alt="...">
  </div>
      <p>1 августа 2021 года парафия преподобного Серафима Саровского в Пуще-Водице отметила Престольный праздник. Разделил радость этого праздничного дня с клириками и прихожанами храма Блаженнейший Митрополит Киевский и всея Украины Онуфрий. Его Блаженству сослужили архиепископ Васильковский Николай, епископ Переяслав-Хмельницкий Дионисий, епископ Ирпенский Лавр, духовенство храма и гости в священном сане.</p>
      <p>После чтения Святого Евангелия Блаженнейший Владыка обратился к пастве со словом проповеди. Предстоятель отметил в своей проповеди что преподобный Серафим является ярким образом святого угодника Божьего, который показал нам, что, живя в миру, можно угождать Богу и жить по Его заповедям. «..Святые стали угодниками Божьими не потому что они долго не ели, не пили и не спали, а потому что подчинились Богу, и за все, что посылал Господь, благодарили Его. Вместо злости человеческой, ненависти они платили любовью. Это сделало святых святыми..»</p>
        <p>После службы Блаженнейший Владыка с духовенством совершили чин прославления возле иконы прп. Серафима Саровского. Предстоятель Украинской Православной Церкви поздравил всех присутствующих с днем памяти покровителя храма, и пожелал, чтобы мы последовали примеру прп. Серафима в деле проповеди Слова Божьего.</p>
      <p>Также хотелось бы выразить огромную благодарность всем тем, кто, несмотря на житейские заботы, помог в приготовлении и организации праздника. Молитвенно желаем вам духовной радости, крепости телесных сил, Божьего благословения во всех начатых вами делах и, дай Бог, чтобы наш приход полноценно развивался и наполнялся новыми, такими же отзывчивыми и неравнодушными к нашему храму прихожанами.</p>
      <p> Больше фото:в разделе "Галерея"</p>
    
    `
  }
  
  export default article_7;