const article_6 = {
    id: 6,
    path: 'articles',
    title: 'Паломническая поездка',
    date: '24.06.2021',
    previewText: 'С 22 по 23 июня 2021 года, по благословению настоятеля храма была организована паломническая поездка',
    previewImgUrl: '/img/articles/main_page/img_1.jpg',
    content: `
    <div class="d-flex justify-content-center mb-5"> 
      <img src="/img/articles/main_page/img_1.jpg" class="img-fluid" alt="...">
    </div>
      
      <p>
      22 июня, по благословению настоятеля, после молебна дети с воскресной школы прихода прп. Серафима Саровского с их родителями отправились к святыням города Львов. Утром этого же дня паломники молились на Божественной Литургии в Свято-Воскресенском Ново-Афонском монастыре. После богослужения группа имела возможность познакомиться с благочинным монастыря – иеремонахом Иовом Ольшанским. После приятного знакомства и беседы с настоятелем паломники прогуливались уютными улицами «старого города Льва» в сопровождении замечательного экскурсовода – Ирины. Они побывали на смотровой площадке, наблюдали за добычей львовского кофе, восхищались архитектурой старых храмов и пробовали вкуснейшие сладости-символы Львова.</p>
  
  
      <p>Уставшие, но воодушевленные поездкой, паломники отправились к обители Пресвятой Богородицы – в Свято-Успенскую Почаевскую Лавру. Там они имели возможность помолиться на вечерней службе, приклониться к святыням, прогуляться по территории Лавры и отдохнуть в местной гостинице. </p>
        
      <p>
      Утром 23 июня паломники помолились на Божественной Литургии в Свято-Успенском соборе Лавры, приклонились к чудотворному образу Матери Божией «Почаевская», к Цельбоносной стопе Божьей Матери, к мощам прп. Иова и Амфилохия Почаевских, посетили экскурсию от студента Почаевской духовной семинарии и отправились к Свято-Духовскому монастырю, где приклонились к святыням, испробовали на себе тяжесть вериг прп. Никиты Столпника, набрали воды с источника, а также покормили животных в местном зоопарке. </p>
  
      <p>Затем с молитвой на устах отправились к подножью Божьей горы, где, по преданию, в 13 веке на Божьей горе появилась Богородица, которая шла спасать Почаевский монастырь от врагов. Ступила одной ногой здесь, на скалу, а второй - на Почаевскую гору. И остались после этого на камне отпечатки Ее святых стоп. А из источника потекла вода с чудодейственной лечебной силой и стали исцеляться больные. </p>
      
      
      <p>Следующим местом посещения стал скит св. прав. Анны в с. Онишковцы, где дети с родителями смогли набрать воды, а также окунуться в источнике. Некоторые дети окунались больше 10 раз, несмотря на то, что температура воды неизменна – +5о С. Последними местами для посещения стали Богоявленский женский монастырь и Кременецкий замок в г. Кременец. Там паломники имели возможность помолиться и узнать немного о истории этих земель.
      </p>

      <p>По дороге в Киев, дети и взрослые обменивались впечатлениями между собой, а также участвовали в интересных дискуссиях на разные темы с сопровождающим поездку священником.  
      </p>

      <p>Эта поездка, программа которой была очень насыщенная, дала возможность каждому еще и еще раз заглянуть в свою душу, поблагодарить Господа Бога за Его щедрые и богатые милости, и попросить Божьего благословения!
      </p>

      <p>В заключение от имени всех наших паломников хотелось бы поблагодарить всех тех людей, благодаря которым наша паломническая поездка состоялась, а именно: о. Иоанна (Грицько) - настоятеля храма прп. Серафима Саровского в Пуще-Водице, также о. Иова (Ольшанского) - благочинного Свято-Воскресенского Ново-Афонского монастыря г. Львов с паствой, и, конечно же, экскурсовода Ирину, водителя Георгия, прихожан храма прп. Серафима Саровского в Пуще-Водице – Дениса Васильевича со сродниками и Веру Филипповну со сродниками. Низкий поклон Вам Всем. И до новых встреч.
      </p>

  
      
    `
  }
  
  export default article_6;