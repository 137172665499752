const gallery_7 = {
    id: 7,
    path: 'gallery',
    title: 'отец Василий Заев',
    date: '2009',
    previewText: '',
    previewImgUrl: '/img/gallery/vasiliy_zaev/0009.jpg',
    photos: [
      {
        src: "/img/gallery/vasiliy_zaev/0001.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/vasiliy_zaev/0002.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/0003.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/vasiliy_zaev/0004.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/0005.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/0006.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/0007.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/0009.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/01.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/02.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/vasiliy_zaev/03.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/vasiliy_zaev/04.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/vasiliy_zaev/05.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/vasiliy_zaev/06.jpg",
        width: 3,
        height: 4
      }
    ]
  }
  
  export default gallery_7;