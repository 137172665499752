import React from 'react';
import {NavLink} from "react-router-dom";

import './navigation.scss';

const Navigation = () => (
    <div className='nav-scroller pt-1 pb-2 container'>
      <nav className='nav d-flex justify-content-between'>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/'>Главная</NavLink>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/schedule'>Расписание Богослужений</NavLink>
        <NavLink activeClassName="text-dark" className='p-2 text-secondary' to='/news'>Новости</NavLink>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/history'>История</NavLink>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/school'>Воскресная школа</NavLink>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/gallery'>Галерея</NavLink>
        <NavLink activeClassName="text-dark" exact className='p-2 text-secondary' to='/contact'>Контакты</NavLink>
      </nav>
    </div>
)

export default Navigation;
