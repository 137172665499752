const gallery_4 = {
    id: 4,
    path: 'gallery',
    title: 'Празднование столетия храма..',
    date: '',
    previewText: '',
    previewImgUrl: '/img/gallery/100_hram/026.jpg',
    photos: [
      {
        src: "/img/gallery/100_hram/026.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/007.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/008.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/009.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/010.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/011.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/012.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/013.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/100_hram/015.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/016.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/017.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/023.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/024.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/025.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/026.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/100_hram/027.jpg",
        width: 1,
        height: 1
      },
      {
        src: "/img/gallery/100_hram/041.jpg",
        width: 4,
        height: 3
      }
    ]
  }
  
  export default gallery_4;