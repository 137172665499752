const historyData = {
  title: 'Воскресная школа',
  content: `
    <div class="row mb-3">
      <div class="col-md-5 col-sm d-flex justify-content-md-center mb-3 mb-lg-0">
        <img src="/img/school/blagoslovenie_detey.jpg" class="img-fluid" alt="...">
      </div>
      <div class="col-md-7 col-sm align-self-center">
        <h5 class="mt-0">Что дети делают на воскресной школе?</h5>
        — "Пустите детей и не препятствуйте им приходить ко Мне, ибо таковых есть Царство Небесное" (Мф. 19, 14), — говорил Господь своим ученикам.
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-7 col-sm align-self-center mb-3 mb-lg-0">
        <h5 class="mt-0"></h5>
        Воскресная школа действует почти в каждом приходе, поскольку работа с детьми является одной из важных сторон приходской жизни и пастырского служения каждого священника. В воскресную школу дети приходят, чтобы научиться Закону Божию и чтобы иметь возможность общения со своими верующими сверстниками.
      </div>
      <div class="col-md-5 col-sm d-flex justify-content-md-center">
        <img src="/img/school/01.jpg" class="img-fluid" alt="...">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-5 col-sm d-flex justify-content-md-center mb-3 mb-lg-0">
        <img src="/img/school/02.jpg" class="img-fluid" alt="...">
      </div>
      <div class="col-md-7 col-sm align-self-center">
        <h5 class="mt-0"></h5>
        В отличие от общеобразовательной школы, где главным является предоставление ребенку определенного объема знаний, воскресная школа имеет целью воспитание ребенка и его воцерковление.Воспитанники воскресных школ периодически совершают паломнические поездки. Они учатся петь церковные песнопения и молиться, а также совершать добрые дела.

      </div>
    </div>
  `
}

export default historyData;