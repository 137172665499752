const gallery_5 = {
    id: 5,
    path: 'gallery',
    title: 'Рождество Христово 201х',
    date: '',
    previewText: '',
    previewImgUrl: '/img/gallery/rojdestvo_201x/03.jpg',
    photos: [
      {
        src: "/img/gallery/rojdestvo_201x/01.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/rojdestvo_201x/02.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/03.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/04.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/05.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/06.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/07.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/08.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/rojdestvo_201x/09.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/10.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/rojdestvo_201x/11.jpg",
        width: 4,
        height: 3
      }
    ]
  }
  
  export default gallery_5;