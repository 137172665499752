const gallery_8 = {
    id: 1,
    path: 'gallery',
    title: 'Паломническая поездка 2021',
    date: '2021',
    previewText: '',
    previewImgUrl: '/img/gallery/palomnik_2021/img_3.jpg',
    photos: [
      {
        src: "/img/gallery/palomnik_2021/img_1.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_2.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_3.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_4.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_5.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_6.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_7.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_8.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_9.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_10.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_12.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_13.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_14.jpg",
        width: 4,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_15.jpg",
        width: 4,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_16.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_17.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_18.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_19.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_20.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_21.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_22.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_23.jpg",
        width: 3,
        height: 4
      },
      {
        src: "/img/gallery/palomnik_2021/img_24.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_25.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_26.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_27.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_28.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_29.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_30.jpg",
        width: 5,
        height: 3
      },
      {
        src: "/img/gallery/palomnik_2021/img_31.jpg",
        width: 5,
        height: 3
      }
    ]
  }
  
  export default gallery_8;