const article_1 = {
  id: 1,
  path: 'articles',
  title: 'ОБРАЩЕНИЕ Священного Синода Украинской Православной Церкви ',
  date: '21.03.2020',
  previewText: 'ОБРАЩЕНИЕ Священного Синода Украинской Православной Церкви в связи с распространением коронавируса COVID-19',
  previewImgUrl: '/img/articles/main_page/sinod_2.jpg',
  content: `
  <div class="d-flex justify-content-center mb-5"> 
    <img src="/img/articles/main_page/sinod_2.jpg" class="img-fluid" alt="...">
  </div>
    <h2>Возлюбленные во Христе братья и сестры!</h2>
    
    <p>
    В настоящее время неожиданных испытаний, связанных с угрозой эпидемии нового инфекционного заболевания, Церковь обращается к своей пастве вечно живыми словами Господа нашего Иисуса Христа: «Не бойся, только веруй!» (Мк. 5:36). Вера в Бога отличает христианина от других членов общества. Вместе с тем, вера проявляет себя именно тогда, когда жизненные проблемы и угрозы ставят человека перед фактом, когда нужно протянуть руку навстречу Христу, как сделал это тонущий в морских волнах святой апостол Петр.</p>


    <p>Для человека естественно быть здоровым, ведь именно таким его создал Бог для блаженной жизни. Из Святого Евангелия мы узнаем, что Господь исцелил многих людей, подарив им физическое здоровье. Однако такое здоровье не было окончательной целью этих исцелений. Самое главное — через принятие дара исцеления уверовать в Того, Кто является Источником жизни. Именно поэтому физические недуги, несущие угрозу смерти, не являются следствием случайного стечения обстоятельств. Святой апостол Иаков объясняет их причину так: «Сделанный грех рождает смерть» (Иак. 1:15). Поэтому, волнуясь как не заболеть от коронавирусной инфекции, общество должно осознать духовные первопричины всего, что случилось. Бог призывает искоренять смертоносный грех, который разрушает человеческую жизнь, покаянием. Особенно теперь, в дни Святой Четыредесятницы, Православная Церковь предлагает все необходимое для принесения достойного плода покаяния, который всегда был приятной жертвой в глазах Господа, что отвращало от человечества кару Божию.</p>
      
    <p>
    Сегодня, перед лицом инфекционной угрозы, христианам следует избегать паники, потому что она является проявлением маловерия. Вместо этого, постом и молитвой надлежит просить у Бога прощения своих грехов. Согласно историческим примерам нашей Церкви, ее пастыри пусть ревностно возносят свои молитвы, неленостно совершая в храмах все надлежащие богослужения и водосвятные молебны о здравии и преодолении инфекции с колокольным звоном и окроплением городов и сел святой водой. Согласно церковной практике, во всех храмах и монастырях во время объявленного государством карантина надлежит ежедневно ударять в церковные колокола, а на святой Литургии возносить особую молитву и прошения на ектении. Благодаря таким искренним церковными молитвам Господь неоднократно посылал Свою благодатную помощь и прекращал смертоносные эпидемии в истории нашего народа. Верим, что и нынешняя эпидемия совместными усиленными молитвами и трудами будет преодолена.</p>

    <p>Церковь призывает своих верных не игнорировать общепринятых норм гигиены и выполнять рекомендации врачей. В случае появления признаков, похожих на инфекционное заболевание, следует оставаться дома, а в случае ухудшения здоровья — обязательно обращаться к врачам. Пастырская опека больных в домашних условиях должна предоставляться с учетом медицинских требований. Настоятелям храмов и монастырей нужно тщательно следить, чтобы чтимые иконы, к которым прикладываются верные, постоянно протирались с дезинфицирующим раствором. После принятия верными Святого Причастия запивку следует подавать в одноразовой посуде. Также следует с особым вниманием следить за чистотой храмовых помещений, чаще проводить проветривание и влажную уборку.</p>
    
    <p>Принимая во внимание установленные государством ограничения по количеству лиц, которые могут присутствовать во время совершения богослужения (по состоянию на сегодня это 10 человек), считается допустимым, в случае необходимости, Таинства Покаяния и Причастия проводить в течение всего дня. В таком случае храм должен быть постоянно открытым, чтобы верующие могли посещать его небольшими группами. В случае если в храме присутствует большее количество верующих, чем это допустимо, богослужение может проводиться на церковном дворе под открытым небом. При этом присутствующие смогут располагаться на таком расстоянии друг от друга, как это предусмотрено действующими санитарно-эпидемиологическими нормами.</p>
    
    <p>Однако все внешние предостережения будут иметь смысл только тогда, когда мы осознаем, что воля Бога, Который сотворил небо и землю, море и все, что в них (см. Пс. 145: 6), заключается в том, чтобы никто из верующих в Него не погиб, но имел жизнь вечную (Ин. 3:16). Пусть вдохновляют всех нас слова Псалтири: «Возвел глаза мои вверх, откуда придет помощь; помощь моя от Господа, сотворившего небо и землю» (Пс. 120:1-2). А по словам святого апостола Петра, покажем в вере нашей праведность, в праведности рассуждение, в рассуждении воздержание, в воздержании терпение, в терпении благочестие, в благочестии братолюбие, а в братолюбии любовь (см. 2Пет 1:5-7). Поэтому в вере и любви, как истинные христиане, встретим все попущенные Богом испытания и с искренней молитвой к нашей небесной Заступнице Пресвятой Богородице и всем Святым сами себя и друг друга, и всю жизнь нашу Христу Богу предадим!
    </p>

    
  `
}

export default article_1;