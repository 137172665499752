import React from 'react';

import articles from "../../data/articles/articles-data";
import ArticlePreviewItem from "./ArticlePreviewItem";

const ArticlePreview = () => (
  <div className='container'>
    <div className="row mb-2">
      {articles.slice(articles.length - 2).reverse().map((item) => (
        <div className="col-md-6" key={item.id}>
          <ArticlePreviewItem item={item} />
        </div>
      ))}
    </div>
  </div>
)

export default ArticlePreview;